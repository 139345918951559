import React, { useState } from 'react'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import './password-reset.scss'

import Layout from '../components/Layout'
import PUSH from '../dao/push'
import Button from '../components/Button'
import { isBrowser } from '../components/Auth'

export default function ResetPasswordPage (props) {
  const queryParams = queryString.parse(props.location.search)
  const tokenPassword = queryParams.token

  if (!tokenPassword) {
    toast.error(
      'El token requerido para resetear la contraseña no está presente en la URL.'
    )
    if (isBrowser()) {
      window.location.href = '/'
    }
  }

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  function submit (e) {
    e.preventDefault()

    if (password !== passwordConfirm || !password) {
      toast.error('Las contraseñas no coinciden.')
      return
    }

    PUSH(
      'PUT',
      'users',
      {
        new_password: password,
        password_confirmation: passwordConfirm,
        token: tokenPassword
      },
      '?action=reset_password'
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else {
        toast.success(res.body.message)
        if (isBrowser()) {
          window.location.href = '/'
        }
      }
    })
  }

  return (
    <Layout className='page-reset-password'>
      <form className='password-form' onSubmit={submit}>
        <h1>Definir contraseña</h1>

        <input
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder='Contraseña'
          className='textfield'
        />

        <input
          type='password'
          value={passwordConfirm}
          onChange={e => setPasswordConfirm(e.target.value)}
          placeholder='Contraseña otra vez'
          className='textfield'
        />

        <div className='text-center'>
          <Button className='btn' type='submit'>
            Enviar
          </Button>
        </div>
      </form>
    </Layout>
  )
}
