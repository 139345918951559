import API from '../components/API'
import { getJwtString } from '../components/Auth'

// PUSH envía información al backend.
//
// @param method string los valores esperados son POST ó PUT.
// @param uri de API.
// @param data Object información que se envía en el body.
// @param queryParams string (opcional)
//
// @return body Object JSON que llega del backend.
// @return error Object si el backend retorna un error.
const PUSH = async (method, uri, data, queryParams = '') => {
  const resp = {
    status: 0,
    body: {}
  }

  try {
    const response = await fetch(`${API(uri)}${queryParams}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getJwtString()
      },
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(data)
    })

    resp.status = response.status

    if (resp.status !== 204) {
      resp.body = await response.json()
    } else {
      resp.body = null
    }
  } catch (err) {
    resp.status = 502
    resp.body.message = err.message
  }

  return resp
}

export default PUSH
